
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonIcon, IonButton, modalController, IonButtons, menuController } from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";

import { dateFormat } from "../services/utils";
import { openToast } from "../services/toast";
import apiDocContabilita from "../services/doc_contabilita";

import DocumentoDetail from "../components/ordini/DocumentoDetail.vue";
import ModalNuovoOrdine from "../components/ordini/ModalNuovoOrdine.vue";

export default {
    name: "OrdiniInterni",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
    },
    setup() {
        const loading = ref(false);
        const userID = JSON.parse(localStorage.getItem("unserInfoEntasys")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("unserInfoEntasys")).dipendenti_id;

        const openMenu = () => {
            menuController.open("app-menu");
        };

        const documents = ref([]);
        async function loadDocumenti() {
            apiDocContabilita;
            loading.value = true;
            try {
                const res = await apiDocContabilita.getDocumentiContabilita(userID, "14");
                console.log(res);
                if (res.status === 200 && res.data.status === 0) {
                    documents.value = res.data.data;
                } else {
                    openToast("Errore durante la richiesta dei documenti", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei documenti", "toast_danger");
            }
            loading.value = false;
        }

        /**
         * ! Set correct customer data
         */
        const riferimentoCliente = (document) => {
            if (document.customers_company) {
                return document.customers_company;
            } else if (document.customers_name && !document.customers_last_name) {
                return `${document.customers_name}`;
            } else if (!document.customers_name && document.customers_last_name) {
                return `${document.customers_last_name}`;
            }
            return `-`;
        };

        /**
         * ! Set correct background for doc type
         */
        const documentType = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == "1") {
                    //fattura
                    className = "badge_low";
                } else if (statusId == "3") {
                    // 	Pro forma
                    className = "badge_high";
                } else if (statusId == "4") {
                    // 	Nota di credito
                    className = "badge_medium";
                } else if (statusId == "5") {
                    // 	Ordine cliente
                    className = "badge_waiting_answer";
                } else if (statusId == "6") {
                    // 	Ordine fornitore
                    className = "badge_open";
                } else if (statusId == "7") {
                    // 	Preventivo
                    className = "badge_open";
                } else if (statusId == "8") {
                    // 	DDT Cliente
                    className = "badge_waiting_answer";
                } else if (statusId == "10") {
                    // 	DDT Fornitore
                    className = "badge_waiting_answer";
                } else if (statusId == "11") {
                    // 	Fattura Reverse
                    className = "badge_low";
                } else if (statusId == "12") {
                    //	Nota di credito Reverse
                    className = "badge_medium";
                } else if (statusId == "14") {
                    // 	Ordine interno
                    className = "badge_canceled";
                }
                return className;
            };
        });

        /**
         * ! Open modal to create new intervento
         */
        async function openNuovoOrdine() {
            const modal = await modalController.create({
                component: ModalNuovoOrdine,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //console.log(detail.data);
                    documents.value.unshift(detail.data.documento);
                    openToast("Ordine creato con successo", "toast_success");
                }
            });
            return modal.present();
        }

        /**
         * ! Open intervento detail
         */
        async function openDocumentoDetail(documento) {
            const modal = await modalController.create({
                component: DocumentoDetail,
                componentProps: {
                    data: documento,
                },
            });
            return modal.present();
        }

        onMounted(() => {
            //GET INTERVENTI DATA
            loadDocumenti();
        });

        return {
            loading,
            add,
            refresh,
            openNuovoOrdine,
            //getRichieste,
            openDocumentoDetail,
            openMenu,
            menu,
            documentType,
            dateFormat,
            riferimentoCliente,
            //Gestione documenti
            documents,
            loadDocumenti,
        };
    },
};
