<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio documento</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="appuntamento_container">
                    <div class="field">
                        <div class="title">Data creazione</div>
                        <div class="value">
                            {{ dateFormat(appuntamento.documenti_contabilita_creation_date) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Cliente</div>
                        <div class="value">
                            {{ riferimentoCliente(appuntamento) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Indirizzo</div>
                        <div class="value">
                            {{ appuntamento.customers_address }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Città</div>
                        <div class="value">
                            {{ appuntamento.customers_city }}
                        </div>
                    </div>
                </div>

                <div class="articoli_container">
                    <div class="articoli_title">Articoli</div>
                    <div v-if="loading" class="loading_spinner">
                        <ion-spinner name="crescent" color="dark"></ion-spinner>
                    </div>
                    <div v-else>
                        <div v-for="(articolo, index) in articoli" :key="index" class="field_articoli">
                            <div class="title">{{ articolo.documenti_contabilita_articoli_codice }} - {{ articolo.documenti_contabilita_articoli_name }}</div>
                            <div class="quantity">Quantità: {{ articolo.documenti_contabilita_articoli_quantita }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    IonSpinner,
    modalController,
} from "@ionic/vue";
import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";

import { arrowBackOutline, arrowForwardCircle } from "ionicons/icons";

import moment from "moment";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";

import apiDocContabilita from "@/services/doc_contabilita";

export default defineComponent({
    name: "ProgrammaLavoroDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonSpinner,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
    },
    setup(props, context) {
        const route = useRoute();
        const id = route.params.id; //richiesta_id by route params

        const loggedUserId = JSON.parse(localStorage.getItem("unserInfoEntasys")).dipendenti_user_id;
        const loggedDipendenteId = JSON.parse(localStorage.getItem("unserInfoEntasys")).dipendenti_id;

        const loading = ref(false);
        const successResponse = ref(false);

        console.log(props.data);
        const appuntamento = ref([]);
        appuntamento.value = { ...props.data };
        //console.log(appuntamento.value);
        console.log(appuntamento.value["documenti_contabilita_id"]);

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Print correct customer info
         */
        const riferimentoCliente = (cliente) => {
            if (cliente.customers_company) {
                return cliente.customers_company;
            } else if (cliente.customers_name && !cliente.customers_last_name) {
                return `${cliente.customers_name}`;
            }
            return `${cliente.customers_name} ${cliente.customers_last_name}`;
        };

        /**
         * Format datetime to DD/MM/YY HH:mm
         */
        function dateTimeFormat(dateTime) {
            if (dateTime && moment.isDate(new Date(dateTime))) {
                return moment(dateTime).format("DD/MM/YYYY HH:mm");
            } else {
                return "-";
            }
        }

        const articoli = ref([]);
        async function loadArticoli() {
            apiDocContabilita;
            loading.value = true;
            try {
                const res = await apiDocContabilita.getArticoliDocumento(appuntamento.value["documenti_contabilita_id"]);
                console.log(res);
                if (res.status === 200 && res.data.status === 0) {
                    articoli.value = res.data.data;
                } else {
                    openToast("Errore durante la richiesta degli articoli", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei articoli", "toast_danger");
            }
            loading.value = false;
        }

        onMounted(() => {
            loadArticoli();
        });

        return {
            loading,
            dateFormat,
            arrowBackOutline,
            closeModal,
            arrowForwardCircle,
            appuntamento,
            dateTimeFormat,
            riferimentoCliente,
            articoli,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
ion-button {
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-primary {
    background: var(--ion-color-primary, #3880ff) !important;
    padding: 8px;
    color: #ffffff;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

/* lead v2*/
.appuntamento_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.appuntamento_container .appuntamento_title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #475569;
    margin-bottom: 8px;
}
.articoli_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.appuntamento_container .city {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 8px;
}
.appuntamento_container .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.articoli_container .articoli_title {
    width: 100%;
    color: #475569;
    font-weight: 600;
    font-size: 18px;
    padding: 12px 6px;
}
.articoli_container .field_articoli {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.articoli_container .field_articoli:last-of-type {
    border-bottom: none;
}
.appuntamento_container .appuntamento_title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #475569;
    margin-bottom: 8px;
}
.articoli_container .field_articoli .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 4px;
}
.articoli_container .field_articoli .quantity {
    font-size: 14px;
}
.appuntamento_container .field_list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.appuntamento_container .field_list .title {
    margin-bottom: 4px;
}
.appuntamento_container .field_list .persone,
.appuntamento_container .field_list .automezzi {
    font-size: 14px;
}
.appuntamento_container .field_description {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.appuntamento_container .field_description .title {
    width: 100%;
    margin-bottom: 4px;
}
.appuntamento_container .field:last-of-type {
    border-bottom: none;
}
.appuntamento_container .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}

.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}
.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}

.container_adesioni {
    width: 100%;
    margin-top: 32px;
}
.btn_crea_intervento {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    color: #ffffff;
    background-color: #086fa3;
}

.partecipazione {
    margin-top: 32px;
    font-size: 16px;
    font-weight: 500;
    color: #475569;
    text-align: center;
}
</style>
